import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { caixaStore } from '@/utils/store-accessor';
let CaixaRetiradaComponent = class CaixaRetiradaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.valorRetirada = 0;
        this.observacao = '';
        this.loading = false;
    }
    onCancel() {
        this.loading = false;
        this.$emit('on-cancel');
    }
    get disableButton() {
        if (this.valorRetirada <= 0 || this.loading) {
            return true;
        }
    }
    async retirarCaixa() {
        this.loading = true;
        await caixaStore.fazerLancamento({
            caixa_id: this.propCaixaId,
            valor: this.valorRetirada * -1,
            observacao: this.observacao,
        });
        this.valorRetirada = 0;
        this.$emit('on-cancel');
    }
};
__decorate([
    Prop({ type: Number })
], CaixaRetiradaComponent.prototype, "propCaixaId", void 0);
CaixaRetiradaComponent = __decorate([
    Component
], CaixaRetiradaComponent);
export default CaixaRetiradaComponent;
