import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { caixaStore } from '@/utils/store-accessor';
let CaixaEntradaComponent = class CaixaEntradaComponent extends Vue {
    constructor() {
        super(...arguments);
        this.valorEntrada = 0;
        this.observacao = '';
        this.loading = false;
    }
    get disableButton() {
        if (this.valorEntrada < 0 || this.loading) {
            return true;
        }
    }
    onCancel() {
        this.loading = false;
        this.$emit('on-cancel');
    }
    async lancarCaixa() {
        this.loading = true;
        await caixaStore.fazerLancamento({
            caixa_id: this.propCaixaId,
            valor: this.valorEntrada,
            observacao: this.observacao,
        });
        this.valorEntrada = 0;
        this.observacao = '';
        this.$emit('on-cancel');
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number })
], CaixaEntradaComponent.prototype, "propCaixaId", void 0);
CaixaEntradaComponent = __decorate([
    Component
], CaixaEntradaComponent);
export default CaixaEntradaComponent;
