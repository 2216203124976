import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dashboardStore, userActionStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import CaixaAbrirComponent from '@/components/CaixaAbrirComponent.vue';
import CaixaEntradaComponent from '@/components/CaixaEntradaComponent.vue';
import CaixaRetiradaComponent from '@/components/CaixaRetiradaComponent.vue';
import FinanceiroAlunoComponent from '@/components/FinanceiroAlunoComponent.vue';
let Caixa = class Caixa extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.alunoSelected = {};
        this.materialHeader = [];
        this.mensalidadelHeader = [];
        this.dialogAbrirCaixa = false;
        this.dialogEntradaCaixa = false;
        this.dialogRetiradaCaixa = false;
        this.caixa = null;
        this.caixaItems = null;
    }
    get dashboardCaixa() {
        return dashboardStore.dashboardCaixa;
    }
    get fazerEntradaCaixaAllow() {
        return userActionStore.fazerEntradaCaixaAllow;
    }
    get fazerRetiradaCaixaAllow() {
        return userActionStore.fazerRetiradaCaixaAllow;
    }
    get saidaTotal() {
        return _.sumBy(this.filteredCaixaItems, (item) => {
            if (item.valor < 0) {
                return item.valor;
            }
        });
    }
    get caixaItemsByFormaDePagamento() {
        return _(this.filteredCaixaItems)
            .groupBy('forma_pagamento_nome')
            .map((item, id) => ({
            forma_pagamento_nome: id,
            valor: _.sumBy(item, 'valor'),
        }))
            .value();
    }
    get caixaItemsByFinanceiroCategoria() {
        return _(this.filteredCaixaItems)
            .groupBy('financeiro_categoria_nome')
            .map((item, id) => ({
            financeiro_categoria_nome: id,
            valor: _.sumBy(item, 'valor'),
        }))
            .value();
    }
    get filteredCaixaItems() {
        return _.filter(this.caixaItems, (val) => val.financeiro_categoria_nome !== 'Troco');
    }
    async onCancel() {
        this.dialogEntradaCaixa = false;
        this.dialogRetiradaCaixa = false;
        this.loading = true;
        this.carregaDados();
    }
    async abrirCaixa() {
        this.dialogAbrirCaixa = !this.dialogAbrirCaixa;
    }
    async entradaCaixa() {
        this.dialogEntradaCaixa = !this.dialogEntradaCaixa;
    }
    async retiradaCaixa() {
        this.dialogRetiradaCaixa = !this.dialogRetiradaCaixa;
    }
    async visualizarCaixa() {
        // @ts-ignore
        const id = dashboardStore.dashboardCaixa.caixa_id;
        this.$router.push({ name: 'main-caixas-edit', params: { id } });
    }
    async carregaDados() {
        this.loading = true;
        await dashboardStore.getDashboardCaixa();
        // @ts-ignore
        this.caixaItems = this.dashboardCaixa.items;
        this.loading = false;
    }
    async mounted() {
        await this.carregaDados();
    }
};
Caixa = __decorate([
    Component({
        components: {
            ShowPopup,
            SearchEntidadeComponent,
            CaixaAbrirComponent,
            CaixaEntradaComponent,
            CaixaRetiradaComponent,
            FinanceiroAlunoComponent,
        },
    })
], Caixa);
export default Caixa;
